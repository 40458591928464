<template>
  <div>
    <div class="pb-2">
      <h3>Product Class Management</h3>
      <hr />
    </div>
    <div class="card">
      <div class="card-body">
        <ValidationObserver ref="productForm">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.productForm"
          />
          <form @submit.prevent="onSubmit">
            <AdminProductClassFields
              :product="product"
              :productTypes="productTypes"
              :attributes="allAttributes"
              @updated="productUpdated"
            />
            <AdminProductClassAttributes
              :classAttributes="product.attributes"
              :allAttributes="allAttributes"
              @updated="attributesUpdated"
            />
            <button
              type="submit"
              class="btn btn-lg btn-primary mt-4 mr-2"
              :disabled="disableSave"
            >
              Save
            </button>
            <button
              class="btn btn-lg btn-secondary mt-4 mr-2"
              @click="cancel"
              type="button"
            >
              Cancel
            </button>
            <p class="mt-2">
              <span style="color: red;">*</span> Indicates a required field
            </p>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import { ValidationObserver } from "vee-validate";
import AdminProductClassFields from "@/components/admin/productclass/AdminProductClassFields.vue";
import AdminProductClassAttributes from "@/components/admin/productclass/AdminProductClassAttributes.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminProductClassAdd",
  title: "Admin - Product Class Add",
  data() {
    return {
      product: {
        attributes: []
      },
      productTypes: [],
      spotTypes: [],
      allAttributes: [],
      disableSave: false,
      errors: []
    };
  },
  components: {
    ValidationObserver,
    AdminProductClassFields,
    AdminProductClassAttributes,
    FormErrorAlert
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getProductTypes() {
      const service = new AdminProductManagementService(this.tenantId);
      service.getAllProductTypes().then(response => {
        this.productTypes = response;
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    onSubmit() {
      this.disableSave = true;
      this.$refs.productForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.productForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.productForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          this.disableSave = false;
        } else {
          this.errors = [];
          this.$store.commit("auth/setLoading", true);
          //Populate object to save
          const service = new AdminProductManagementService(this.tenantId);
          service
            .addProduct(this.product)
            .then(response => {
              if (response.statusCode == "Success") {
                this.$router
                  .push("/admin/product-class-management")
                  .catch(() => {});
              }
            })
            .finally(() => {
              this.disableSave = false;
              this.$store.commit("auth/setLoading", false);
            });
        }
      });
    },
    attributesUpdated(updatedAttributes) {
      this.product.attributes = updatedAttributes;
    },
    productUpdated(updatedProduct) {
      this.product.productClassName = updatedProduct.productClassName;
      this.product.productClassDescription =
        updatedProduct.productClassDescription;
      this.product.productClassInternalDescription =
        updatedProduct.productClassInternalDescription;
      this.product.productTypeId = updatedProduct.productTypeId;
      this.product.spotTypeIds = updatedProduct.spotTypeIds;
      this.product.attributeIconIds = updatedProduct.attributeIconIds;
      this.product.equipmentTypeIds = updatedProduct.equipmentTypeIds;
      this.product.message = updatedProduct.message;
      this.product.applyMessageToWebsite = updatedProduct.applyMessageToWebsite;
      this.product.applyMessageToEmail = updatedProduct.applyMessageToEmail;
      this.product.showMessageAtTop = updatedProduct.showMessageAtTop;
    },
    getAttributes() {
      const service = new AdminTenantService(this.tenantId);
      service.getAttributes().then(response => {
        this.allAttributes = response.data;
      });
    }
  },
  created() {
    this.getProductTypes();
    this.getAttributes();
  }
};
</script>
